import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Job } from '../../../../../../services/jobs.service';
import { AosModule } from '../../../../aos/aos.module';

@Component({
    selector: 'app-job-item',
    templateUrl: './job-item.component.html',
    styleUrls: ['./job-item.component.scss'],
    imports: [RouterLink, AosModule],
    standalone: true
})
export class JobItemComponent {
    @Input() job?: Job;
    @Input() uid?: string;

    constructor(private route: ActivatedRoute, private dom: DomSanitizer) {}

}
